<template>
  <div>

    <CModal
            title="Atención"
            color="danger"
            :show.sync="warningModal"
    >
      {{alerta_txt}}
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>

    <CRow>


      <CCol sm="12" v-show="pagina == 0">
        <CForm validated>
        <CCard>
          <CCardHeader>
            <strong>Datos</strong> <small> personales</small>
            <div class="card-header-actions">
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CInput
                  label="Nombre(s)"
                  placeholder=""
                  required
                  valid-feedback=""
                  invalid-feedback="Capture su nombre"
                  was-validated
                  v-model="datosPersonales.nombres"

                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                        label="Apellido Paterno"
                        placeholder=""
                        required
                        valid-feedback=""
                        invalid-feedback="Capture su apellido"
                        was-validated
                        v-model="datosPersonales.apaterno"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                        label="Apellido Materno"
                        placeholder=""
                        v-model="datosPersonales.amaterno"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <CInput
                        label="Telefono celular (10 digitos)"
                        placeholder=""
                        required
                        valid-feedback=""
                        invalid-feedback="Capture su telefono celular"
                        was-validated
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength = "10"
                        @keypress="isNumber($event)"
                        v-model="datosPersonales.celular"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">

                
                <CSelect
                        label="Género"
                        :options="generos"
                        :value.sync="genero_select"
                        selected="MASCULINO"
                        

                />
              </CCol>
              <CCol sm="4">
                <CSelect
                        label="Estado de nacimiento"
                        :options="estados"
                        :value.sync="estado_select"

                />
              </CCol>

              <CCol sm="4">
                <CSelect
                        label="Municipio de nacimiento"
                        :options="municipios"
                        :value.sync="municipio_select"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="4">
                <CSelect
                        label="¿Trabajas actualmente?"
                        :options="['SI','NO']"
                        :value.sync="trabajas"
                />
              </CCol>
              <CCol sm="4">
                <CSelect
                        label="¿Tienes hijos?"
                        :options="['NO', '1', '2', '3', '4', '5 o MAS']"
                        :value.sync="hijos"
                />
              </CCol>

              <CCol sm="4">
                <CSelect
                        label="Tipo sanguineo"
                        :options="['O+','O-','B+','B-','A+','A-','AB+','AB-']"
                        :value.sync="tipoSanguineo"
                />
              </CCol>
            </CRow>

          </CCardBody>
          <CCardFooter>
            <CCol sm="12" style="text-align: right">
              <CButton size="sm" color="primary" @click="siguente_pagina0()">Siguente</CButton>
            </CCol>
          </CCardFooter>
          </CCard>
      </CForm>
      </CCol>
      <CCol sm="12" v-show="pagina == 1">
        <CForm validated>
        <CCard>
          <CCardHeader>
            <strong>Domicilio </strong><small>actual</small>
          </CCardHeader>
          <CCardBody>
            <CInput
              label="Calle y número:"
              placeholder=""
              v-model="domicilio.calle"
              required
              valid-feedback=""
              invalid-feedback="Capture su calle"
              was-validated
            />
            <CInput
              label="Colonia"
              placeholder=""
              v-model="domicilio.colonia"
              required
              valid-feedback=""
              invalid-feedback="Capture su colonia"
              was-validated
            />

            <CRow>
              <CCol sm="4">
                <CSelect
                        label="Estado"
                        :options="estados"
                        :value.sync="estado_select2"
                />
              </CCol>

              <CCol sm="4">
                <CSelect
                        label="Municipio"
                        :options="municipios2"
                        :value.sync="municipio_select2"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  label="Codigo postal"
                  placeholder=""
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength = "5"
                  @keypress="isNumber($event)"
                  v-model="domicilio.cp"
                  required
                  valid-feedback=""
                  invalid-feedback="Capture su cp"
                  was-validated
                />
              </CCol>
            </CRow>
            <CInput
              label="Telefono"
              placeholder=""
              v-model="domicilio.telefono"
            />

          </CCardBody>
          <CCardFooter>
            <CRow>

            <CCol sm="6" style="">
              <CButton size="sm" color="primary" @click="atras_pagina1()">Atras</CButton>
            </CCol>
            <CCol sm="6" style="text-align: right">
              <CButton size="sm" color="primary" @click="siguente_pagina1()">Siguente</CButton>
            </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
        </CForm >
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" v-show="pagina == 2">

        <CCard>
          <CCardHeader>
            <strong>Datos del Domicilio del Padre, Madre </strong> o Persona de contacto
          </CCardHeader>
          <CCardBody>
            <CForm validated>
              <CRow>
                <CCol sm="12">
                  <CInput
                          label="Nombre completo"
                          placeholder=""
                          required
                          valid-feedback=""
                          invalid-feedback="Capture el nombre"
                          was-validated
                          v-model="domicilio2.nombre"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CInput
                          label="Telefono"
                          placeholder=""
                          required
                          valid-feedback=""
                          invalid-feedback="Capture el telefono"
                          was-validated
                          v-model="domicilio2.telefono"
                  />
                </CCol>
              </CRow>
              <p>
                <b> Domicilio:</b>
              </p>

              <input type="checkbox" id="checkbox" v-model="mismo_domicilio">&nbsp;
              <label for="checkbox">Marque esta casilla si el domicilio es el mismo que el suyo</label>

              <div v-if="mismo_domicilio == false">
              <CInput
                      label="Calle y número:"
                      placeholder=""
                      required
                      valid-feedback=""
                      invalid-feedback="Capture la calle"
                      was-validated
                      v-model="domicilio2.calle"
              />
              <CInput
                      label="Colonia"
                      placeholder=""
                      required
                      valid-feedback=""
                      invalid-feedback="Capture la colonia"
                      was-validated
                      v-model="domicilio2.colonia"
              />

              <CRow>
                <CCol sm="4">
                  <CSelect
                          label="Estado"
                          :options="estados"
                          :value.sync="estado_select3"
                  />
                </CCol>

                <CCol sm="4">
                  <CSelect
                          label="Municipio"
                          :options="municipios3"
                          :value.sync="municipio_select3"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                          label="Codigo postal"
                          placeholder=""
                          required
                          valid-feedback=""
                          invalid-feedback="Capture el cp"
                          was-validated
                          v-model="domicilio2.cp"
                  />
                </CCol>
              </CRow>

              </div>

            </CForm>
          </CCardBody>
          <CCardFooter>
            <CRow>

              <CCol sm="6" style="">
                <CButton size="sm" color="primary" @click="atras_pagina2()">Atras</CButton>
              </CCol>
              <CCol sm="6" style="text-align: right">
                <CButton size="sm" color="primary" @click="siguiente_pagina2()">Siguente</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>


      </CCol>
      <CCol sm="12" v-show="pagina == 3">
        <CCard style="height: 650px;">
          <CCardHeader>
            <strong>Datos academicos</strong> Escuela de origen
          </CCardHeader>
          <CCardBody>
            <CForm>
              <CRow>

                <CCol sm="6">
                  <CSelect
                          label="Estado"
                          :options="escuelaEstados"
                          :value.sync="escuelaEstado"
                  />

                </CCol>

                <CCol sm="6">
                  <CSelect
                          label="Municipio"
                          :options="escuelaMunicipios"
                          :value.sync="escuelaMunicipio"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12" v-if="escuela_listado == false">
                  <CSelect
                          label="Escuela"
                          :options="escuelas"
                          :value.sync="escuela_select"
                  />
                </CCol>
                <CCol sm="12">
                  <input type="checkbox" id="checkbox2" v-model="escuela_listado">&nbsp;
                  <label for="checkbox">Marque esta casilla si la escuela no se encuentra en el listado</label>

                </CCol>
              </CRow>
              <CRow v-if="escuela_listado == true">
                <CCol sm="12">
                  <CInput
                          label="Nombre de la escuela"
                          placeholder=""
                          required
                          valid-feedback=""
                          invalid-feedback="Capture el nombre de la escuela"
                          was-validated
                          v-model="escuela.nombre2"
                  />
                </CCol>
              </CRow>
              <CRow>
              <CCol sm="6">
                <CSelect
                        label="Mes de egreso"
                        :value.sync="escuela.mes"
                        :options="['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                        label="Año de egreso"
                        placeholder=""
                        required
                        valid-feedback=""
                        invalid-feedback="Capture el año"
                        was-validated
                        v-model="escuela.agno"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength = "4"
                        @keypress="isNumber($event)"
                />
              </CCol>

            </CRow>
              <CRow>
                <CCol sm="4">
                  <CInput
                          label="Promedio"
                          type="number"
                          placeholder=""
                          required
                          v-model="escuela.promedio"
                          valid-feedback=""
                          invalid-feedback="Capture un promedio valido"
                          was-validated
                          min="0"
                          max="10"  
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength = "3"
                          step=".01"
                          @keypress="isNumber($event)"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                          label="Extraordinarios"
                          placeholder=""
                          required
                          v-model="escuela.extraordinarios"
                          valid-feedback=""
                          invalid-feedback="Capture el numero de extraordinarios"
                          was-validated
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength = "2"
                          @keypress="isNumber($event)"
                  />
                </CCol>
                <CCol sm="4">
                  <CInput
                          label="Materias recursadas"
                          placeholder=""
                          required
                          v-model="escuela.materias"
                          valid-feedback=""
                          invalid-feedback="Capture el numero de materias recursadas"
                          was-validated
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength = "2"
                          @keypress="isNumber($event)"
                  />
                </CCol>

              </CRow>

            </CForm>
          </CCardBody>
          <CCardFooter>
            <CRow>

              <CCol sm="6" style="">
                <CButton size="sm" color="primary" @click="atras_pagina3()">Atras</CButton>
              </CCol>
              <CCol sm="6" style="text-align: right">
                <CButton size="sm" type="submit" color="primary" @click="siguiente_pagina3()">Siguente</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>

      </CCol>
      <CCol sm="12" v-show="pagina == 4">
        <CCard>
          <CCardHeader>
            <strong>Anexar</strong> Documentos, recuerda nombrar los requisitos con el nombre correspondiente a Fotografía, certificado o constancia y Acta de nacimiento.
          </CCardHeader>

          <CCardBody>
            <!-- Bootstrap Vue has some problems with Inline forms that's why we use some standard bootstrap classes -->
            <CForm inline>
              <CRow>
                <CCol sm="4">
                  <strong>Fotografía</strong> <small>  tipo credencial, fondo claro de buena resolución, misma que servirá para la credencial de estudiante en el caso que sea aceptado. (No Foto de celular)
                  &nbsp;</small>
                  <br/>
                 <input type="file" accept="image/png, image/jpeg" id="fileFoto" ref="fileFoto" v-on:change="onChangeFoto()" @click="previsualizar_img_clic()" />
              
                  <span v-show="fileFoto!=''" class="badge badge-pill badge-success">OK</span>
                   <span class="badge badge-pill badge-warning">Recuerda que debes de subir la Fotografía de acuerdo a las características mencionadas y en posición correcta.</span>
                  <br>
                   <br>
                    <img v-show="fileFoto!=''" id="imagenPrevisualizacion"  width="50%" height="60%" >
                      <!-- <img  id="imagenPrevisualizacion" > -->
                    <br>
       

                </CCol>

                <CCol sm="4">

                      <strong>Certificado de terminación de estudios</strong> <small> Legalizado en caso de ser necesario, si aun no cuentas con el documento ingresar constancia original de estudios con promedio general y grado que cursa, así como la modalidad de estudios ejemplo: bachillerato cuatrimestral, semestral etc. Debe contar con sellos y firma de la autoridad responsable. El documento correspondiente deberá subirse en formato PDF
                      &nbsp;</small>
                  <input type="file" accept=".pdf" id="fileCertificado" ref="fileCertificado" v-on:change="onChangeCertificado()"/>
                   <span v-show="fileCertificado!=''" class="badge badge-pill badge-success">OK</span>
                </CCol>
                <CCol sm="4">
                  <input type="file" accept=".pdf" id="fileActa" ref="fileActa" v-on:change="onChangeActa()"/>
                      <span v-show="fileActa!=''" class="badge badge-pill badge-success">OK</span>
                      <strong> Acta de nacimiento</strong> <small> actualizada del año en curso o trámite expedido desde el sitio <a href="https://www.gob.mx/ActaNacimiento/">https://www.gob.mx/ActaNacimiento/</a>
                      &nbsp;</small>
                </CCol>
              </CRow>

            </CForm>
          </CCardBody>
          <CCardFooter>
            <CCol sm="6" style="">

            </CCol>
            <CCol sm="6" style="text-align: right">
              <CButton size="sm" color="primary" @click="uploadAll()">Enviar Documentos</CButton>
            </CCol>

            <!-- <CCol sm="12" style="text-align: center; margin-top: 20px;">
                <p>
                  <CButton  style="width: 200px; margin-left: auto; margin-right: auto" color="success" @click="salir()" block>Salir</CButton>
                </p>

              </CCol> -->



             </CCardFooter>
        </CCard>
      </CCol>
      <CCol sm="12" v-show="pagina == 5">
        <CCard>
          <CCardHeader>
            <strong>Expediente en revision</strong>
          </CCardHeader>

          <CCardBody>
            <!-- Bootstrap Vue has some problems with Inline forms that's why we use some standard bootstrap classes -->
            <CForm inline>
              <CRow>
                <CCol sm="12">
                  <small> su expediente esta en revisión, se le notificara por correo en caso de ser aprobado/rechazado
                  &nbsp;</small>
                  <br/>
                </CCol>

                <!-- <CCol sm="12" style="text-align: center">
                 <p>
                   <CButton color="success" @click="salir()" block>Salir</CButton>
                 </p>

                </CCol> -->


              </CRow>




            </CForm>
          </CCardBody>
          <CCardFooter>
             </CCardFooter>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>
export default {
  name: 'Forms',
  data () {
    return {
      pagina:localStorage.getItem('pagina'),
      //pagina:'3',
      id:localStorage.getItem('id'),
      escuela_select:'',

      mismo_domicilio: false,
      datosPersonales: {},
      domicilio: {},
      domicilio2: {},
      escuela: { 
        promedio:0
      },
      escuelaEstado : 4717,
      escuelaEstados: [],
      escuelaMunicipios:[],
      escuelaMunicipio:'',
      escuela_listado: false,

      celular: '',
      genero_select: '',
      trabajas: 'NO',
      hijos: 'NO',
      tipoSanguineo: 'O+',
      estados: [],
      generos:[],
      municipios: [],
      municipios2:[],
      municipios3:[],
      municipios4:[],
      escuelas: [],
      selected: [], // Must be an array reference!
      show: true,
     errores:[],
      estado_select: 11,
      municipio_select: 362,
      estado_select2: 11,
      municipio_select2: 362,
      estado_select3: 11,
      municipio_select3: 362,

      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      warningModal: false,
      alerta_txt: '',
      fileFoto: '',
      fileCertificado: '',
      fileActa: '',
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        {
          value: 'some value',
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }



  },
  watch: {
    escuelaMunicipio:function(){
      this.getEscuelas(this.escuelaMunicipio)
      this.escuela.municipio = this.escuelaMunicipio
    },
    escuelaEstado: function(){
      this.get_escuelaMunicipios(this.escuelaEstado)
      this.escuela.estado = this.escuelaEstado
    },
    estado_select: function() {
      this.get_municipios(this.estado_select)
      this.datosPersonales.idEstado = this.estado_select
    },
    estado_select2: function() {
      this.get_municipios2(this.estado_select2)
      this.domicilio.idEstado = this.estado_select2
    },
    estado_select3: function() {
      this.get_municipios3(this.estado_select3)
      this.domicilio2.idEstado = this.estado_select3
    },
    estado_select4: function() {
      alert(this.estado_select4)
      this.get_municipios4(this.estado_select4)
      this.escuela.idEstado = this.estado_select4
    },
    municipio_select: function(){
      this.datosPersonales.idMunicipio = this.municipio_select
    },
    municipio_select2: function(){
      this.domicilio.idMunicipio = this.municipio_select2
    },
    municipio_select3: function(){
      this.domicilio2.idMunicipio = this.municipio_select3
    },
    municipio_select4: function(){
      this.getEscuelas(this.municipio_select4)
      this.escuela.idMunicipio = this.municipio_select4
    },
    trabajas: function(){
      this.datosPersonales.trabajas = this.trabajas
    },
    hijos: function(){
      this.datosPersonales.hijos = this.hijos
    },
    tipoSanguineo: function(){
      this.datosPersonales.tipoSanguineo = this.tipoSanguineo
    },
    escuela_select: function(){
      this.escuela.nombre = this.escuela_select
    }


  },

  created(){
    this.escuela.estado = 4717
    this.escuela.municipio = 4924
    this.escuela.nombre = 1358

    this.getEscuelas(4924);
    this.get_estados();
    this.getEscuelasEstados();
    this.get_municipios(11);
    this.get_municipios2(11);
    this.get_municipios3(11);
    this.get_escuelaMunicipios(4717);
    this.get_generos();
    // this.previsualizar_img_clic();
    //this.getDatosPersonales()
    //this.getDomicilio()
  },
  directives: {
    uppercase: {
      // Definición de directiva
      update (el) {
     //   el.value = el.value.toUpperCase()
        el.model = 'Test'
      },
    }
  },
  methods: {


    isNumber: function(evt) {
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },


    upload_url(){
      return "https://sae.upbicentenario.edu.mx/upload/index.php?id=" + this.id;
    },
    validator (val) {
      return val ? val.length >= 4 : false
    },
    uploadAll(){

      let formData = new FormData();
      formData.append('fileFoto', this.fileFoto);
      formData.append('fileCertificado', this.fileCertificado);
      formData.append('fileActa', this.fileActa);
      this.$http.post('https://cdn.upbicentenario.edu.mx/api/uploadAdmision/' + this.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
    ).then(response => {

        if(response.data.mensaje == '1'){
          this.alerta_txt = "se han guardado los datos correctamente";
          this.warningModal = true;
          this.pagina = 5
        }else{
          this.alerta_txt = response.data.mensaje;
          this.warningModal = true;
        }
      });
    },

previsualizar_img_clic(){

              const $fileFoto = document.querySelector("#fileFoto"),
                $imagenPrevisualizacion = document.querySelector("#imagenPrevisualizacion");

                 // Escuchar cuando cambie
                 fileFoto.addEventListener("change", () => {
                   // Los archivos seleccionados, pueden ser muchos o uno
                   const archivos = $fileFoto.files;
                   // Si no hay archivos salimos de la función y quitamos la imagen
                   if (!archivos || !archivos.length) {
                     $imagenPrevisualizacion.src = "";
                     return;
                   }
                   // Ahora tomamos el primer archivo, el cual vamos a previsualizar
                   const primerArchivo = archivos[0];
                   // Lo convertimos a un objeto de tipo objectURL
                   const objectURL = URL.createObjectURL(primerArchivo);
                   // Y a la fuente de la imagen le ponemos el objectURL
                     $imagenPrevisualizacion.src = objectURL;
                    
                 });

},

onChangeFoto(){
  
      var fileName = document.getElementById("fileFoto").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
            this.fileFoto = this.$refs.fileFoto.files[0];
              //  console.log(this.fileFoto.name);
              //  console.log(fileName);
              
        }else{
            alert("¡Solo imagenes jpg/jpeg y png estan permitidas!");
            document.getElementById("fileFoto").value=''
        }

      
    },
    onChangeCertificado(){
      this.fileCertificado = this.$refs.fileCertificado.files[0];
    },
    onChangeActa(){
      this.fileActa = this.$refs.fileActa.files[0];
    },
    siguente_pagina1(){
      let mensaje = '1'
      if(this.domicilio.calle.length<1){
         mensaje = "Faltan datos de capturar"
      }
      if(this.domicilio.colonia.length<1){
        mensaje = "Faltan datos de capturar"
      }
      if(this.domicilio.cp.length<1){
        mensaje = "Faltan datos de capturar"
      }

      if(mensaje == '1'){
        this.$http.post('/guardardomicilio', {
          id: this.id,
          domicilio: this.domicilio
        }).then(response => {
          if(response.data.mensaje == '1'){
            //  this.alerta_txt = "se han guardado los datos correctamente";
            // this.warningModal = true;
            this.pagina = 2;
          }else{
            this.alerta_txt = response.data.mensaje;
            this.warningModal = true;

          }
        })
      }else{
        this.alerta_txt = mensaje;
        this.warningModal = true;
      }


//this.pagina = 2
},
atras_pagina1(){
        this.$http.post('/guardardomicilio', {
          id: this.id,
          domicilio: this.domicilio
        }).then(response => {
          if(response.data.mensaje == '1'){
            //  this.alerta_txt = "se han guardado los datos correctamente";
            // this.warningModal = true;
            this.pagina = 0
          }else{
            this.alerta_txt = response.data.mensaje;
            this.warningModal = true;

          }

      })

},
    siguiente_pagina2(){
      let mensaje = '1'

      if(mensaje == '1'){
        this.$http.post('/guardardomicilio2', {
          id: this.id,
          domicilio: this.domicilio2,
          mismo_domicilio: this.mismo_domicilio
        }).then(response => {
          if(response.data.mensaje == '1'){
            //  this.alerta_txt = "se han guardado los datos correctamente";
            // this.warningModal = true;
            this.pagina = 3;
          }else{
            this.alerta_txt = response.data.mensaje;
            this.warningModal = true;

          }
        })
      }else{
        this.alerta_txt = mensaje;
        this.warningModal = true;
      }


//this.pagina = 2
    },
    atras_pagina2(){
      this.$http.post('/guardardomicilio2', {
        id: this.id,
        domicilio: this.domicilio2,
        mismo_domicilio: this.mismo_domicilio
      }).then(response => {
        if(response.data.mensaje == '1'){
          //  this.alerta_txt = "se han guardado los datos correctamente";
          // this.warningModal = true;
          this.pagina = 1
        }else{
          this.alerta_txt = response.data.mensaje;
          this.warningModal = true;

        }

      })

    },
    siguiente_pagina3(){

    if(this.escuela.promedio>10 || this.escuela.promedio<0){
      return false;

    }
      let mensaje = '1'

      if(mensaje == '1'){
        this.$http.post('/guardarescuela', {
          id: this.id,
          escuela: this.escuela,
          listado:this.escuela_listado,
        }).then(response => {
          if(response.data.mensaje == '1'){
            //  this.alerta_txt = "se han guardado los datos correctamente";
            // this.warningModal = true;
            this.pagina = 4;
          }else{
            this.alerta_txt = response.data.mensaje;
            this.warningModal = true;

          }
        })
      }else{
        this.alerta_txt = mensaje;
        this.warningModal = true;
      }


//this.pagina = 2
    },
    atras_pagina3(){
      this.$http.post('/guardarescuela', {
        id: this.id,
        escuela: this.escuela,
        listado:this.escuela_listado,
      }).then(response => {
        if(response.data.mensaje == '1'){
          //  this.alerta_txt = "se han guardado los datos correctamente";
          // this.warningModal = true;
          this.pagina = 2
        }else{
          this.alerta_txt = response.data.mensaje;
          this.warningModal = true;

        }

      })

    },

    atras_pagina4(){
      this.pagina = 3
    },

    siguente_pagina0(){

      let mensaje = '1'
      this.datosPersonales.genero = this.genero_select

      if(this.datosPersonales.nombres.length<1){
        mensaje = "Faltan datos de capturar"
      }
      if(this.datosPersonales.apaterno.length<1){
        mensaje = "Faltan datos de capturar"
      }
      if(this.datosPersonales.celular.length<1){
        mensaje = "Faltan datos de capturar"
      }
      if(mensaje == "1"){
        this.$http.post('/guardargeneraladmision', {
          id: this.id,
          datosPersonales: this.datosPersonales
        }).then(response => {
          if(response.data.mensaje == '1'){
            //  this.alerta_txt = "se han guardado los datos correctamente";
            // this.warningModal = true;
            this.pagina = 1;
          }else{
            this.alerta_txt = response.data.mensaje;
            this.warningModal = true;

          }
          // do something
        })
      }else{
        this.alerta_txt = mensaje;
        this.warningModal = true;
      }

    },
    getDatosPersonales(){
      this.$http.get("getDatosPersonales/" + this.id).then(
              response => {
                // console.log(response.data)
                if(response){
                  this.datosPersonales = response.data.datos[0];

                  this.estado_select = this.datosPersonales.idEstado
                  this.municipio_select = this.datosPersonales.idMunicipio
                  this.trabajas = this.datosPersonales.trabajas
                  this.hijos = this.datosPersonales.hijos
                  this.tipoSanguineo = this.datosPersonales.tipoSanguineo
                }


                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },
    getDomicilio(){
      this.$http.get("getDomicilio/" + this.id).then(
              response => {
                // console.log(response.data)
                if(response){
                  this.domicilio = response.data.domicilio[0];

                  this.estado_select2 = this.domicilio.idestado
                  this.municipio_select2 = this.domicilio.idmunicipio
                }



                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },
    get_estados(){
      this.$http.get("estados_admision").then(
              response => {
                //  console.log(response.data)
                this.estados = response.data.estados;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },
      get_generos(){
      this.$http.get("get_generos").then(
              response => {
                //  console.log(response.data)
                this.generos = response.data.generos;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },
    getEscuelasEstados(){
      this.$http.get("getEscuelasEstados").then(
              response => {
                // console.log(response.data)
                this.escuelaEstados = response.data.estados;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },



    getEscuelas(municipio){
      // console.log(municipio)

      this.$http.get("getEscuelas/" + municipio).then(
              response => {
                // console.log(response.data)
                this.escuelas = response.data.escuelas;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );



    },

    get_municipios(estado){
      // console.log(estado)

      this.$http.get("municipios_admision/" + estado).then(
              response => {
                // console.log(response.data)
                this.municipios = response.data.municipios;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );

    },
    // salir(){
    //   this.$router.push('/pages/login')
    // },
    get_municipios2(estado){
      // console.log(estado)

      this.$http.get("municipios_admision/" + estado).then(
              response => {
                // console.log(response.data)
                this.municipios2 = response.data.municipios;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );



    },
    get_municipios3(estado){
      // console.log(estado)

      this.$http.get("municipios_admision/" + estado).then(
              response => {
                // console.log(response.data)
                this.municipios3 = response.data.municipios;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );



    },
    get_escuelaMunicipios(estado){
      // console.log(estado)

      this.$http.get("getmunicipiosEscuela/" + estado).then(
              response => {
                // console.log(response.data)
                this.escuelaMunicipios = response.data.municipios;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );



    }
}
}
</script>
<style>
  .form-control{
    text-transform: uppercase !important;
  }
</style>
